(function ($, Drupal) {
  Drupal.behaviors.customCollectionSkuListSizesV1 = {
    attach: function (context) {
      this.setElements(context);
      this.setEvents();
      this.setActive($(this.$skuListItems[0]));
    },

    setElements: function (context) {
      this.$container = $('.js-custom-collection-sku-list-sizes-v1', context);
      this.$skuListItems = $('.js-sku-list-sizes-button', this.$container);
      this.$skuPrices = $('.js-custom-collection__prices-multi-sized', context);
      this.$addToBagCta = $('.js-custom-collection-product-add-v1', context);
    },

    setEvents: function () {
      var self = this;

      this.$skuListItems.on('click', function (event) {
        event.preventDefault();

        self.setActive($(this));
      });
    },

    setActive: function ($item) {
      var skuBaseId = $item.attr('data-sku-base-id');
      var activeClassName = 'sku-list-sizes__button--active';
      var activeClassNamePrice = 'custom-collection__prices-multi-sized--active';

      this.$skuListItems
        .removeClass(activeClassName)
        .filter('[data-sku-base-id=' + skuBaseId + ']')
        .addClass(activeClassName);

      this.$skuPrices
        .removeClass(activeClassNamePrice)
        .filter('[data-sku-base-id=' + skuBaseId + ']')
        .addClass(activeClassNamePrice);

      this.$addToBagCta.data('sku-base-id', skuBaseId);

      // Need to queue call to custom event as on initial call the custom event listener hasn't been set.
      setTimeout(function () {
        $item
          .closest('.js-product')
          .trigger('product.skuSelect', [skuBaseId]);
      }, 1000);
    }
  };
})(jQuery, Drupal);
